<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        :link="'/sell/distribution/distributor/daily'"
    >
        <section v-loading="loading" style="margin-top: 25px;">
            <gb-echart
                v-if="hasData"
                ref="distributorSale"
                config="distributor-sale-config"
                height="100%"
                width="100%"
            />
            <Empty v-else />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import GbEchart from '@/components/Echart'
import Empty from '@/components/empty'
import { getDistributionDailyReport } from 'services/distribution'
export default {
    name: '',
    components: { CardComponent, GbEchart, Empty },
    data () {
        return {
            title: '分销TOP5分销商销量',
            tip: '分销TOP5分销商销量',
            icon: 'iconfont icon-zoushitu',
            hasData: true,
            loading: false
        }
    },
    created () {
        this.getDistributorTop5()
    },
    methods: {
    // 获取top5分销商销售额数据
        async getDistributorTop5 () {
            try {
                const param = {
                    groupIds: [],
                    selectFrom: this.$moment().subtract(7, 'day').format('YYYY-MM-DD'),
                    selectTo: this.$moment().subtract(1, 'day').format('YYYY-MM-DD'),
                    distributorName: []
                }
                this.loading = true
                const { data = [] } = await getDistributionDailyReport(param)

                if (data && data.length > 0) {
                    this.hasData = true
                    let top5 = data.splice(0, 5)
                    // console.log(top5[0].distributionDailyReports.map(item => this.$moment(item.theDate).format('MM-DD')));
                    let xAxisData =
                        top5[0].distributionDailyReports.map((item) =>
                            this.$moment(item.theDate).format('MM-DD')
                        )
                    let legendData = top5.map((item) => item.distributorName)
                    // console.log(legendData);
                    let seriesData = []
                    let color = ''
                    top5.forEach((item, index) => {
                        switch (index) {
                        case 0:
                            color = '#DF5A68'
                            break
                        case 1:
                            color = '#E6A23C'
                            break
                        case 2:
                            color = '#3595E1'
                            break
                        case 3:
                            color = '#39A36B'
                            break
                        case 4:
                            color = '#7158DE'
                            break
                        }
                        let obj = {
                            name: '',
                            type: 'line',
                            smooth: true,
                            data: [],
                            itemStyle: {
                                color
                            }
                        }
                        obj.name = item.distributorName
                        obj.data = item.distributionDailyReports.map(
                            (item) => item.itemCount
                        )
                        seriesData.push(obj)
                    })
                    let echart = this.$refs.distributorSale
                    // console.log(this.$refs.distributorSale);
                    echart.option.legend.data = legendData
                    echart.option.xAxis.data = xAxisData
                    echart.option.series = seriesData
                    echart.refresh()
                } else {
                    this.hasData = false
                }
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
            // this.$axios.fetch('distributionServer', '/distribution/distributor-daily-report', {
            //     groupIds: [],
            //     selectFrom: this.$moment().subtract(7, 'day').format('YYYY-MM-DD'),
            //     selectTo: this.$moment().subtract(1, 'day').format('YYYY-MM-DD'),
            //     distributorName: []
            // }, 'json').then(res => {
            //     // console.log(res);
            //     if (res.data && res.data.length > 0) {
            //         this.hasData = true
            //         let top5 = res.data.splice(0, 5)
            //         // console.log(top5[0].distributionDailyReports.map(item => this.$moment(item.theDate).format('MM-DD')));
            //         let xAxisData = top5[0].distributionDailyReports.map(item => this.$moment(item.theDate).format('MM-DD'))
            //         let legendData = top5.map(item => item.distributorName)
            //         // console.log(legendData);
            //         let seriesData = []
            //         let color = ''
            //         top5.forEach((item, index) => {
            //             switch (index) {
            //             case 0:
            //                 color = '#DF5A68'
            //                 break
            //             case 1:
            //                 color = '#E6A23C'
            //                 break
            //             case 2:
            //                 color = '#3595E1'
            //                 break
            //             case 3:
            //                 color = '#39A36B'
            //                 break
            //             case 4:
            //                 color = '#7158DE'
            //                 break
            //             }
            //             let obj = {
            //                 name: '',
            //                 type: 'line',
            //                 smooth: true,
            //                 data: [],
            //                 itemStyle: {
            //                     color
            //                 }
            //             }
            //             obj.name = item.distributorName
            //             obj.data = item.distributionDailyReports.map(item => item.itemCount)
            //             seriesData.push(obj)
            //         })
            //         let echart = this.$refs.distributorSale
            //         // console.log(this.$refs.distributorSale);
            //         echart.option.legend.data = legendData
            //         echart.option.xAxis.data = xAxisData
            //         echart.option.series = seriesData
            //         echart.refresh()
            //     } else {
            //         this.hasData = false
            //     }
            // })
        }
    }
}
</script>
<style lang='less' scoped>
</style>
